/** Vuex module */
export default {
    namespaced: true,
    state: {
        theaters: [],
        filtered: [],
    },
    mutations: {
        /**
         * @param {object} state
         * @param {array} models
         * @return void
         */
        theaters(state, models = []) {
            state.theaters = models;
        },
    },
    getters: {
        all: state => state.theaters,
        filtered: state => state.filtered,
        empty: state => state.theaters.length === 0,
        tags: state => {
            let tags = [];

            state.theaters.forEach(model => {
                tags = tags.concat([], model.tags);
            });

            return tags;
        },
        search: (state) => (term, models) => {
            models = models || state.theaters;

            term = new RegExp(term, 'i');

            return state.filtered = models.filter(t => {
                return term.test(t.name)
                    || term.test(t.code)
                    || term.test(t.city)
                    || (t.group && term.test(t.group))
            });
        },
        filter: (state) => (tags, models) => {
            models = models || state.theaters;

            return state.filtered = models.filter(model => {
                return model.tags
                    .filter(tag => tags.indexOf(tag.type + '-' + tag.name.en) > -1)
                    .length
            });
        }
    }
};
