<template>
    <div class="oc-menu">
        <div class="app-wrap">
            <b-nav tabs align="right" class="nav app-nav-tabs pt-2">
                <b-nav-item class="bg-darker" to="/theaters" :active="tab === 'theaters'">Theaters</b-nav-item>
                <b-nav-item class="bg-darker" to="/users" :active="tab === 'users'">Users</b-nav-item>
                <b-nav-item class="bg-darker" to="/settings" :active="tab === 'settings'">Settings</b-nav-item>
                <b-nav-item class="bg-darker" v-on:click.prevent="logout">Sign out</b-nav-item>
            </b-nav>
        </div>
    </div>
</template>

<script>
    import {BNavItem, BNav} from 'bootstrap-vue/esm/index.js';

    export default {
        components: {
            BNav,
            BNavItem,
        },
        data() {
            return {
                tab: 'theaters'
            }
        },
        watch: {
            tab(value) {
                this.$emit('view', value);
                // this.shiftAppTop(); todo menu button in all views
            }
        },
        methods: {
            logout() {
                this.$stargate.logout();
                this.shiftAppTop();
            }
        }
    }
</script>
