
export default {

    /**
     * Call bootstrap-vue toast
     * @param {string} type
     * @param {string} title
     * @param {string} body
     */
    toast(type, title, body = '') {
        this.$bvToast.toast(body, {
            title: title,
            autoHideDelay: 5000,
            appendToast: false,
            variant: type,
            solid: true
        })
    },

    /**
     * Show top off-canvas menu
     * @return void
     */
    shiftAppTop() {
        const el = document.getElementById('app-content');
        el.classList.remove('shift-left');
        el.classList.toggle('shift-top');
    },

    /**
     * Show left off-canvas menu
     * @return void
     */
    shiftAppLeft() {
        const el = document.getElementById('app-content');
        el.classList.remove('shift-top');
        el.classList.toggle('shift-left');
    },

    /**
     * Spin the spinner inside a submit button
     * @param {Element} parent
     * @param {int|boolean} state
     * @return void
     */
    spinBtn(parent, state = 1) {
        const spinner = parent.querySelector('button[type=submit] span');
        if (spinner) spinner.classList[state ? 'remove' : 'add']('d-none');
    },

    /**
     * Build a query string
     * @param {object} data
     * @return {string}
     */
    makeQueryString(data) {
        let str = [];
        Object.keys(data).forEach(k => str.push(k + '=' + data[k]));
        return '?' + str.join('&');
    },

    /**
     * Return the query string of an url
     * @param {string} url
     * @return {string}
     */
    getQueryString(url = '') {
        let str = (url || window.location.href).split('?');
        return str.length === 2 ? str.pop() : '';
    },

    /**
     * Cast string to lowercase
     * @param {string} str
     * @return {string}
     */
    lc: str => str.toLowerCase(),

    /**
     * Capitalize a string.
     * @param {string} str
     * @return {string}
     */
    capitalize: str => {
        return str.charAt(0).toUpperCase() + str.slice(1)
    },

    /**
     * Pad a string or number with a given character
     * @param {number|string} str
     * @param {number} length
     * @param {string} char
     * @return {string}
     */
    pad: (str, length = 2, char = '0') => String((char.repeat(length)) + str.toString()).slice(-length),

    /**
     * Calculate screen aspect ratios
     * @param {number|string} width
     * @param {number|string} height
     * @return {string}
     */
    aspectRatio: (width, height) => (parseInt(width, 10) / parseInt(height, 10)).toFixed(2),
}

