<template>
    <div class="theater-view">
        <div v-if="show">
            <b-table :fields="fields" :items="screens" class="theater-screens table-sm">
                <template slot="name" slot-scope="data">
                    {{ data.item.name }}
                </template>
                <template slot="aspect-ratio" slot-scope="data">
                    {{ aspectRatio(data.item.width, data.item.number) }}
                </template>
                <template slot="active" slot-scope="data">
                    <CheckCircleIcon v-if="data.item.active"/>
                    <XCircleIcon v-else/>
                </template>
                <template slot="actions" slot-scope="data">
                    <EditIcon v-on:click="showingModal = data.item.id"/>
                    <screen-modal v-if="showModal(data.item.id)" @close="showingModal = null" v-bind:screen="data.item"/>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
    import {EditIcon, CheckCircleIcon, XCircleIcon} from 'vue-feather-icons'
    import {BTable} from 'bootstrap-vue/esm/index.js'
    import ScreenModal from './ScreenModal'

    export default {
        components: {
            EditIcon,
            CheckCircleIcon,
            XCircleIcon,
            BTable,
            ScreenModal
        },
        props: ['show', 'screens'],
        mounted() {
            //console.log('mounted screens view', this);
        },
        data () {
            return {
                test: {
                    seats: 666
                },
                hello: 'world',
                showingModal: null,
                fields: [
                    {key: 'number', sortable: true, label: '#', class: 'text-right'},
                    {key: 'name', sortable: false},
                    {key: 'group', sortable: true},
                    {key: 'seats', sortable: true, class: 'text-right'},
                    {key: 'width', sortable: true, class: 'text-right'},
                    {key: 'height', sortable: true, class: 'text-right'},
                    {key: 'aspect-ratio', sortable: true, label: 'Aspect Ratio', class: 'text-right'},
                    {key: 'active', sortable: true, class: 'text-center'},
                    {key: 'tags', sortable: false},
                    {key: 'actions', sortable: false, label: ''},
                ],
            }
        },
        methods: {
            toggleModal(screenId, state) {
                this.showingModals[screenId] = state;
                console.log('toggling modal', screenId, state);
            },
            showModal(screenId) {
                //console.log('v-if', screenId, !!this.showingModals[screenId]);
                return false;//!!this.showingModals[screenId];
            }
        },
        watch: {
            screens: {
                handler(newN) {
                    //console.log('changed deep watched screens data', newN);
                },
                deep: true
            },
            showingModals: {
                handler(newN) {
                    //console.log('showingModals CHANGE', newN);
                },
                deep: true
            }
        },
    }
</script>
