var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "input-group" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-group-append flex-grow-1" },
        [
          _c("vue-tags-input", {
            attrs: {
              id: "excluded-theaters",
              placeholder: "Insert codes or ids to be excluded from import",
              tags: _vm.tags,
            },
            on: { "tags-changed": _vm.save },
            model: {
              value: _vm.current,
              callback: function ($$v) {
                _vm.current = $$v
              },
              expression: "current",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _vm._v("Exclude from import"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }