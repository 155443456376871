var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-mask" }, [
    _c("div", { staticClass: "modal-wrapper" }, [
      _c("div", { staticClass: "modal-container" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _vm._t("header", function () {
              return [
                _vm._v("\n                    Edit screen\n                "),
              ]
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-xs-6" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      "label-cols-sm": "3",
                      label: "Width:",
                      "label-align-sm": "right",
                      "label-for": "scr-width",
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: { id: "scr-width", type: "number" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-xs-6" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      "label-cols-sm": "3",
                      label: "Height:",
                      "label-align-sm": "right",
                      "label-for": "scr-height",
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: { id: "scr-height", type: "number" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-xs-6" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      "label-cols-sm": "3",
                      label: "Seats:",
                      "label-align-sm": "right",
                      "label-for": "scr-seats",
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: { id: "scr-seats", type: "number" },
                      model: {
                        value: _vm.screen.seats,
                        callback: function ($$v) {
                          _vm.$set(_vm.screen, "seats", $$v)
                        },
                        expression: "screen.seats",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-xs-6" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      "label-cols-sm": "3",
                      label: "Group:",
                      "label-align-sm": "right",
                      "label-for": "scr-group",
                    },
                  },
                  [
                    _c("b-form-select", {
                      attrs: { id: "scr-group", options: _vm.groups },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "modal-default-button",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("Save")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }