var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "input-group" }, [
      _c("div", { staticClass: "input-group-prepend flex-grow-1" }, [
        _c("span", { staticClass: "input-group-text w-100" }, [
          _vm._v("Force import " + _vm._s(this.name) + " theatres"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-group-append" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.sync.apply(null, arguments)
              },
            },
          },
          [_vm._v("Synchronise!")]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("small", { staticClass: "text-muted font-italic" }, [
      _vm._v("Last synced on "),
      _c("span", { staticClass: "sync-time" }, [
        _vm._v(_vm._s(this.timestamp || "-")),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }