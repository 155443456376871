var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "oc-menu" }, [
    _c(
      "div",
      { staticClass: "app-wrap" },
      [
        _c(
          "b-nav",
          {
            staticClass: "nav app-nav-tabs pt-2",
            attrs: { tabs: "", align: "right" },
          },
          [
            _c(
              "b-nav-item",
              {
                staticClass: "bg-darker",
                attrs: { to: "/theaters", active: _vm.tab === "theaters" },
              },
              [_vm._v("Theaters")]
            ),
            _vm._v(" "),
            _c(
              "b-nav-item",
              {
                staticClass: "bg-darker",
                attrs: { to: "/users", active: _vm.tab === "users" },
              },
              [_vm._v("Users")]
            ),
            _vm._v(" "),
            _c(
              "b-nav-item",
              {
                staticClass: "bg-darker",
                attrs: { to: "/settings", active: _vm.tab === "settings" },
              },
              [_vm._v("Settings")]
            ),
            _vm._v(" "),
            _c(
              "b-nav-item",
              {
                staticClass: "bg-darker",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.logout.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Sign out")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }