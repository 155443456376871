import Vue from 'vue'
import Vuex from 'vuex'
import notifyStore from './modules/store-notify';
import filtersStore from './modules/store-filters';
import modelsStore from './modules/store-models';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        notify: notifyStore,
        filters: filtersStore,
        models: modelsStore,
    },
    state: {},
    mutations: {},
    getters: {}
})
