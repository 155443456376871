var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-wrap" },
    [
      _c("theaters-search", {
        model: {
          value: _vm.filters.term,
          callback: function ($$v) {
            _vm.$set(_vm.filters, "term", $$v)
          },
          expression: "filters.term",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "ul",
            { staticClass: "theaters" },
            _vm._l(_vm.filtered, function (theater) {
              return _c("theater", {
                key: theater.id,
                attrs: { theater: theater },
              })
            }),
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("b-modal", {
        staticClass: "rounded-sm",
        attrs: {
          id: "create-theatre",
          size: "xl",
          "header-class": "p-0 border-0",
          "body-class": "p-0",
          "hide-footer": "",
        },
        on: { hide: this.getData },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function () {
              return [_c("theater", { attrs: { theater: {}, create: true } })]
            },
            proxy: true,
          },
          {
            key: "modal-footer",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }