<template>
    <div>
        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text">Exclude from import</span>
            </div>
            <div class="input-group-append flex-grow-1">
                <vue-tags-input
                    id="excluded-theaters"
                    placeholder="Insert codes or ids to be excluded from import"
                    v-model="current"
                    :tags="tags"
                    @tags-changed="save"/>
            </div>
        </div>
    </div>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';

    export default {
        components: {
            VueTagsInput
        },
        props: ['arr'],
        data() {
            return {
                tags: this.arr,
                current: ''
            }
        },
        methods: {
            save(tags) {
                this.$emit('change', this.tags = tags)
            }
        },
        watch: {
            arr(v) {
                this.tags = v
            }
        }
    }
</script>
