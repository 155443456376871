var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("login", {
    attrs: {
      "app-name": "Park",
      "app-logo":
        "https://images.brightfish.be/apps/park/park-logo-colored-428.png",
      "app-logo-width": "180",
      title: "Exhibitors and screens inventory",
      "bg-color": "#2b4d42",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }