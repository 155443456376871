var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "theater-view" },
    [
      _vm.show
        ? _c(
            "b-form",
            {
              staticClass: "needs-validation",
              attrs: { novalidate: "" },
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-md-9",
                      attrs: { label: "Name", "label-for": "th-name" },
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "th-name",
                          placeholder: "Theater name",
                          required: "",
                        },
                        model: {
                          value: _vm.name,
                          callback: function ($$v) {
                            _vm.name = $$v
                          },
                          expression: "name",
                        },
                      }),
                      _vm._v(" "),
                      !_vm.$v.name.required
                        ? _c(
                            "b-form-invalid-feedback",
                            { staticClass: "invalid-tooltip" },
                            [
                              _vm._v(
                                "\n                    A name is required\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-md-3",
                      attrs: { label: "Code", "label-for": "th-code" },
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "th-code",
                          placeholder: "Code",
                          required: "",
                        },
                        model: {
                          value: _vm.code,
                          callback: function ($$v) {
                            _vm.code = $$v
                          },
                          expression: "code",
                        },
                      }),
                      _vm._v(" "),
                      !_vm.$v.code.required
                        ? _c(
                            "b-form-invalid-feedback",
                            { staticClass: "invalid-tooltip" },
                            [
                              _vm._v(
                                "\n                    A code is required\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-md-8" },
                  [
                    _c("label", { attrs: { for: "th-tags-name" } }, [
                      _vm._v("Alternative names"),
                    ]),
                    _vm._v(" "),
                    _c("vue-tags-input", {
                      attrs: {
                        id: "th-tags-name",
                        placeholder: "Alternative theatre names",
                        tags: _vm.tags.name,
                      },
                      on: {
                        "tags-changed": (newTags) => (_vm.tags.name = newTags),
                      },
                      model: {
                        value: _vm.currentTags.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentTags, "name", $$v)
                        },
                        expression: "currentTags.name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form-group col-md-4" }, [
                  _c("label", { attrs: { for: "aa-playlist-api-key" } }, [
                    _vm._v("Advertising Accord Playlist API key"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.aa_playlist_api_key,
                        expression: "aa_playlist_api_key",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      id: "aa-playlist-api-key",
                      placeholder: "API key",
                    },
                    domProps: { value: _vm.aa_playlist_api_key },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.aa_playlist_api_key = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "form-group col-md-6" },
                  [
                    _c("label", { attrs: { for: "th-tags-park" } }, [
                      _vm._v("CineParks"),
                    ]),
                    _vm._v(" "),
                    _c("vue-tags-input", {
                      attrs: {
                        id: "th-tags-park",
                        placeholder: "Brightfish parks for this theatre",
                        tags: _vm.tags.park,
                        "autocomplete-items": _vm.parks,
                        "add-only-from-autocomplete": "",
                      },
                      on: {
                        "tags-changed": (newTags) => (_vm.tags.park = newTags),
                      },
                      model: {
                        value: _vm.currentTags.park,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentTags, "park", $$v)
                        },
                        expression: "currentTags.park",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class:
                      "mb-3 col-md-" + (_vm.theater.kinepolis_id ? "3" : "6"),
                  },
                  [
                    _c("label", { attrs: { for: "gofilex-id" } }, [
                      _vm._v("Gofilex ID"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "gofilex-id",
                        required: "",
                        readonly: "",
                      },
                      domProps: { value: _vm.theater.gofilex_id },
                    }),
                  ]
                ),
                _vm._v(" "),
                _vm.theater.kinepolis_id
                  ? _c("div", { staticClass: "col-md-3 mb-3" }, [
                      _c("label", { attrs: { for: "kinepolis-id" } }, [
                        _vm._v("Kinepolis Code"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "kinepolis-id",
                          required: "",
                          readonly: "",
                        },
                        domProps: { value: _vm.theater.kinepolis_id },
                      }),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-row mt-3 align-items-center pl-1" },
                [
                  _c("div", { staticClass: "mr-4" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "submit" },
                      },
                      [
                        _c("span", {
                          staticClass:
                            "d-none spinner-border spinner-border-sm",
                          attrs: { role: "status", "aria-hidden": "true" },
                        }),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.create ? "Create" : "Update") +
                            "\n                "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mr-4" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            name: "adm_include",
                            switch: "",
                            size: "md",
                          },
                          model: {
                            value: _vm.adm_include,
                            callback: function ($$v) {
                              _vm.adm_include = $$v
                            },
                            expression: "adm_include",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    Include in admission stats\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { name: "active", switch: "", size: "md" },
                          model: {
                            value: _vm.active,
                            callback: function ($$v) {
                              _vm.active = $$v
                            },
                            expression: "active",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    Active theatre\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }