<template>
    <div class="theater-view">
        <b-form v-if="show" class="needs-validation" novalidate @submit.stop.prevent="submit">

            <div class="form-row">
                <b-form-group class="col-md-9" label="Contact name" label-for="c-name">
                    <b-form-input type="text"
                                  class="form-control"
                                  id="c-name"
                                  placeholder="Contact person"
                                  v-model="contact_name"
                                  required />
                    <b-form-invalid-feedback class="invalid-tooltip" v-if="!$v.contact_name.required">
                        A contact person is required
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group class="col-md-3" label="Preferred language" label-for="c-lang">
                    <b-form-select v-model="preferred_lang"
                                   id="c-lang"
                                   :options="languages"
                                   :state="$v.preferred_lang.$dirty ? !$v.preferred_lang.$error : null" />
                    <b-form-invalid-feedback class="invalid-tooltip" v-if="!$v.preferred_lang.required">
                        A language is required
                    </b-form-invalid-feedback>
                </b-form-group>
            </div>

            <div class="form-row">
                <b-form-group class="col-md-6 mb-3" label="Mobile number" label-for="c-mobile">
                    <b-form-input type="tel"
                                  pattern="\+[0-9]{11}"
                                  class="form-control"
                                  id="c-mobile"
                                  placeholder="+32444555666"
                                  v-model="mobile"
                                  required />
                    <b-form-invalid-feedback class="invalid-tooltip" v-if="!$v.mobile.required">
                        A mobile number is required
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group class="col-md-6 mb-3" label="Phone number" label-for="c-phone">
                    <b-form-input type="tel"
                                  pattern="\+[0-9]{10}"
                                  class="form-control"
                                  id="c-phone"
                                  placeholder="+3245556677"
                                  v-model="phone" />
                </b-form-group>
            </div>

            <div class="form-row">
                <b-form-group class="col-md-6 mb-3" label="Contact email" label-for="c-mail">
                    <b-form-input type="email"
                                  class="form-control"
                                  id="c-mail"
                                  placeholder="Email address"
                                  v-model="email"
                                  required />
                    <b-form-invalid-feedback class="invalid-tooltip" v-if="!$v.email.required">
                        An email address is required
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group class="col-md-6 mb-3" label="Theater website" label-for="c-web">
                    <b-form-input type="url"
                                  class="form-control"
                                  id="c-web"
                                  placeholder="URL"
                                  v-model="website"
                                  required />
                    <b-form-invalid-feedback class="invalid-tooltip" v-if="!$v.website.required">
                        The website is required
                    </b-form-invalid-feedback>
                </b-form-group>
            </div>

            <div class="form-row">
                <b-form-group class="col-md-12 mb-3" label="Street address" label-for="th-address">
                    <b-form-input type="text"
                                  class="form-control"
                                  id="th-address"
                                  placeholder="Street address"
                                  v-model="address_line_1"
                                  required/>
                    <b-form-invalid-feedback class="invalid-tooltip" v-if="!$v.zip.required">
                        This is required
                    </b-form-invalid-feedback>
                </b-form-group>
            </div>

            <div class="form-row">
                <b-form-group class="col-md-3 mb-3" label="Zip" label-for="th-zip">
                    <b-form-input type="number"
                                  class="form-control"
                                  id="th-zip"
                                  placeholder="Zip"
                                  v-model="zip"
                                  required
                                  min="0"/>
                    <b-form-invalid-feedback class="invalid-tooltip" v-if="!$v.zip.numeric">
                        The postal code should be a number
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback class="invalid-tooltip" v-if="!$v.zip.required">
                        This is required
                    </b-form-invalid-feedback>
                </b-form-group>

                <div class="col-md-3 mb-3">
                    <label for="validationTooltip03">City</label>
                    <input type="text" class="form-control" id="validationTooltip03" placeholder="City" required
                           v-model="city">
                    <div class="invalid-tooltip">
                        Please provide a valid city
                    </div>
                </div>

                <div class="form-group col-md-3">
                    <label :for="'th-tags-region-' + code">Region</label>
                    <vue-tags-input
                        :id="'th-tags-region-' + code"
                        placeholder="Region"
                        v-model="currentTags.region"
                        :tags="tags.region"
                        :max-tags="1"
                        :autocompleteItems="$config.regions.map(r => ({text: r}))"
                        :validation="regionValidation()"
                        @tags-changed="newTags => tags.region = newTags"/>
                    <div class="invalid-tooltip">
                        Please provide a valid region
                    </div>
                </div>

                <div class="col-md-3 mb-3">
                    <label for="validationTooltip04">Country</label>
                    <input type="text" class="form-control" id="validationTooltip04" placeholder="State" required
                           v-model="country">
                    <div class="invalid-tooltip">
                        Please provide a valid country
                    </div>
                </div>
            </div>

            <button class="btn btn-primary" type="submit">
                <span class="d-none spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Update
            </button>
        </b-form>
    </div>
</template>

<script>
    import {BForm, BFormGroup, BFormInput, BFormSelect, BFormInvalidFeedback} from 'bootstrap-vue/esm/index.js';
    import VueTagsInput from '@johmun/vue-tags-input';
    import {required, numeric} from 'vuelidate/lib/validators';

    export default {
        components: {
            BForm,
            BFormGroup,
            BFormInput,
            BFormSelect,
            BFormInvalidFeedback,
            VueTagsInput
        },
        props: ['show', 'theater', 'code'],
        data() {
            this.languages = [
                { value: null, text: '...' },
                { value: 'NL', text: 'Dutch' },
                { value: 'EN', text: 'English' },
                { value: 'FR', text: 'French' },
                { value: 'DU', text: 'German' },
            ];

            return {
                form: 'contact',
                contact_name: this.theater.contact_name,
                preferred_lang: this.theater.preferred_lang,
                phone: this.formatPhone(this.theater.phone),
                mobile: this.formatPhone(this.theater.mobile),
                website: this.theater.website,
                email: this.theater.email,
                address_line_1: this.theater.address_line_1,
                zip: this.theater.zip,
                city: this.theater.city,
                country: this.theater.country,
                tags: {
                    region: this.parseTagsFromServer('region', this.theater.tags),
                },
                currentTags: {
                    region: '',
                },
            }
        },
        validations: {
            contact_name: {required},
            preferred_lang: {required},
            mobile: {required},
            website: {required},
            email: {required},
            address_line_1: {required},
            zip: {required, numeric},
            city: {required},
            country: {required},
        },
        methods: {
            submit() {
                this.$el.querySelector('form').classList.add('was-validated');

                this.$v.$touch();

                if (this.$v.$anyError) {
                    return;
                }

                this.spinBtn(this.$el);

                this.$http
                    .put('theaters/' + this.theater.id, this.formatDataForServer(this.$data))
                    .then(() => {
                        this.spinBtn(this.$el, 0);
                        this.toast('success', this.theater.name, 'Theater contact data saved!');
                    })
                    .catch(error => {
                        this.spinBtn(this.$el, 0);
                        console.log(error);
                        this.toast('danger', 'Error saving theater', error.message);
                    });
            },
            formatPhone(number) {
                return number ? '+' + number : '';
            },
            parseTagsFromServer(type, tags = []) {
                return tags
                    .filter(tag => tag.type === type)
                    .map(tag => ({text: tag.name.en}))
            },
            formatDataForServer(data) {
                data = Object.assign({}, data);
                data.preferred_lang = data.preferred_lang.toUpperCase();
                data.phone = data.phone ? parseInt(data.phone.replace(/[^\d]/, ''), 10) : null;
                data.mobile = data.mobile ? parseInt(data.mobile.replace(/[^\d]/, ''), 10) : null;

                delete data.currentTags;

                data.tags = {
                    region: data.tags.region.map(tag => tag.text),
                };

                return data;
            },
            regionValidation() {
                return [{
                    classes: 'is-valid',
                    disableAdd: true,
                    rule: tag => !!tag.text && this.$config.regions.indexOf(tag.text) === -1,
                }];
            },
        }
    }
</script>
