<template>
    <div class="view-404 fixed-center">
        <h4>This page doesn't exist.</h4>
        <p><a href="#" @click="back"><i class="ico-arrow-left-circle"></i> Go back</a></p>
    </div>
</template>

<script>
    import router from '../router';
    export default {
        methods: {
            back(e) {
                e.preventDefault();
                router.back()
            }
        }
    }
</script>
