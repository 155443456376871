<template>
    <div class="container">
        <div class="row">
            <div class="d-flex w-100 align-items-center justify-content-between">
                <div class="col-auto p-0">
                    <button class="btn btn-primary" @click="shiftAppLeft"><equaliser-icon/></button>
                </div>
                <div class="col-auto px-2 flex-lg-grow-1">
                    <div class="input-group my-4">
                        <input :value="value"
                               v-on:input="e => this.$emit('input', e.target.value)"
                               type="search"
                               class="form-control"
                               placeholder="Search theaters"
                               aria-label="Search a theater"
                               aria-describedby="btn-search"/>
                        <div class="input-group-append">
                            <button class="btn btn-primary" type="button" id="btn-search">Search</button>
                        </div>
                    </div>
                </div>
                <div class="col-auto p-0 pr-2">
                    <button class="btn btn-primary" v-b-modal.create-theatre><plus-icon/></button>
                </div>
                <div class="col-auto p-0">
                    <button class="btn btn-primary" @click="shiftAppTop"><menu-icon/></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {MenuIcon, FilterIcon, PlusIcon} from 'vue-feather-icons'
    import EqualiserIcon from '../partials/icons/equaliser'

    export default {
        components: {
            MenuIcon,
            FilterIcon,
            EqualiserIcon,
            PlusIcon
        },
        data() {
            return {
                value: ''
            }
        }
    }
</script>
