var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "theater-view" }, [
    _vm.show
      ? _c(
          "div",
          [
            _c("b-table", {
              staticClass: "theater-screens table-sm",
              attrs: { fields: _vm.fields, items: _vm.screens },
              scopedSlots: _vm._u(
                [
                  {
                    key: "name",
                    fn: function (data) {
                      return [
                        _vm._v(
                          "\n                " +
                            _vm._s(data.item.name) +
                            "\n            "
                        ),
                      ]
                    },
                  },
                  {
                    key: "aspect-ratio",
                    fn: function (data) {
                      return [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.aspectRatio(data.item.width, data.item.number)
                            ) +
                            "\n            "
                        ),
                      ]
                    },
                  },
                  {
                    key: "active",
                    fn: function (data) {
                      return [
                        data.item.active
                          ? _c("CheckCircleIcon")
                          : _c("XCircleIcon"),
                      ]
                    },
                  },
                  {
                    key: "actions",
                    fn: function (data) {
                      return [
                        _c("EditIcon", {
                          on: {
                            click: function ($event) {
                              _vm.showingModal = data.item.id
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.showModal(data.item.id)
                          ? _c("screen-modal", {
                              attrs: { screen: data.item },
                              on: {
                                close: function ($event) {
                                  _vm.showingModal = null
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                false,
                564223894
              ),
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }