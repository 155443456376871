<template>
    <div>
        <div class="input-group">
            <div class="input-group-prepend flex-grow-1">
                <span class="input-group-text w-100">Force import {{ this.name }} theatres</span>
            </div>
            <div class="input-group-append">
                <button class="btn btn-primary" type="button" @click.prevent="sync">Synchronise!</button>
            </div>
        </div>
        <small class="text-muted font-italic">Last synced on <span class="sync-time">{{ this.timestamp || '-' }}</span></small>
    </div>
</template>

<script>
    export default {
        props: ['service', 'timestamp'],
        data() {
            return {
                name: this.capitalize(this.service)
            }
        },
        methods: {
            sync() {
                this.$http
                    .post('settings/sync/' + this.service)
                    .then(response => {
                        let missing = Object.keys(response.data.data.missing);
                        missing = missing.length
                            ? `${missing.length} theaters missing (an email has been sent to the service desk)`
                            : 'All theatre data has been successfully synced';

                        // Update the timestamp in the UI
                        this.changeTime(response.data.data[`health_age_${this.service}`]);

                        // Show the toast message
                        this.toast('success', `Syncing ${this.name} done!`, missing);
                    })
                    .catch(error => {
                        this.toast('danger', `Error syncing ${this.name}!`, error.message);
                    });
            },
            changeTime(time) {
                this.$el.querySelector('.sync-time').textContent = time;
            }
        }
    }
</script>
