import axios from "axios";
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import router from './router';
import helpers from './helpers/helpers';
import store from './store';
import config from './config';
import AppMenuTop from './components/AppMenuTop';
import AppMenuLeft from './components/AppMenuLeft';
import BCollapse from 'bootstrap-vue/esm/index.js';
import BToast from 'bootstrap-vue/esm/index.js';
import SgCallbackView from "@brightfish/stargate-client-pkce/src/components/Callback.vue";
import SgUser from "@brightfish/stargate-client-pkce/src/User";
import Stargate from "@brightfish/stargate-client-pkce/src/index";

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['X-Brightfish-UI'] = '1';
axios.defaults.baseURL = config.apiBase;

Vue.prototype.$http = axios;
Vue.prototype.$config = config;

Vue.use(Vuelidate);
Vue.use(BCollapse);
Vue.use(BToast);

Vue.mixin({
    methods: helpers
});

Vue.use(Stargate, {
    router: router,
    callbackView: SgCallbackView,
    User: SgUser,
    initialIntrospect: false,
    logLevel: 3,
});

new Vue({
    el: '#app',
    router,
    store,
    components: {
        AppMenuLeft,
        AppMenuTop
    },
    mounted() {
        this.setLoggedClassName(this.logged);
    },
    computed: {
        logged() {
            return this.$stargate.loggedIn
        }
    },
    watch: {
        logged(logged) {
            this.setLoggedClassName(logged);
        }
    },
    methods: {
        setLoggedClassName(logged) {
            this.$el.classList[logged ? 'add' : 'remove']('logged')
        }
    }
});
