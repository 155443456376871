<template>
    <div class="app-wrap">
        <theaters-search v-model="filters.term"></theaters-search>
        <div class="container">
            <div class="row">
                <ul class="theaters">
                    <theater v-for="theater in filtered" :key="theater.id" :theater="theater" />
                </ul>
            </div>
        </div>
        <b-modal id="create-theatre"
                 @hide="this.getData"
                 size="xl"
                 header-class="p-0 border-0"
                 body-class="p-0"
                 class="rounded-sm"
                 hide-footer>
            <template v-slot:modal-header>
                <theater :theater="{}" :create="true" />
            </template>
            <template v-slot:modal-footer></template>
        </b-modal>
    </div>
</template>

<script>
    import Theater from './Theater';
    import TheatersSearch from './TheatersSearch';

    export default {
        components: {
            Theater,
            TheatersSearch
        },
        data() {
            return {
                collection: null,
                filtered: [],
                filters: {
                    tags: [],
                    term: ''
                }
            }
        },
        mounted() {
            this.getData();

            this.$store.watch((state, getters) => {
                this.filters.tags = getters['filters/filter'];
            });
        },
        methods: {
            getData() {
                this.$http
                    .get('theaters')
                    .then(response => {
                        let models = response.data.data;

                        this.$store.commit('models/theaters', models);

                        this.filtered = models;

                        this.$store.commit('filters/add', this.$store.getters['models/tags']);
                    })
                    .catch(error => {
                        this.toast('danger', 'Error while fetching theaters', error.message);
                    });
            }
        },
        watch: {
            filters: {
                handler(filters) {

                    if (!this.$store.getters['models/empty']) {

                        // Search the collection if we have a search term
                        let collection = filters.term
                            ? this.$store.getters['models/search'](filters.term)
                            : this.$store.getters['models/all'];

                        // Filter the collection if we have tags
                        this.filtered = filters.tags.length
                            ? this.$store.getters['models/filter'](filters.tags, collection)
                            : collection;
                    }
                },
                deep: true
            }
        }
    }
</script>
