/** Vuex module */
export default {
    state: {
        msg: '',
        type: ''
    },
    mutations: {
        /**
         * @param {object} state
         * @param {{msg: string, type: string}} data
         * @return void
         */
        notify(state, data) {
            state.type = data.type;
            state.msg = data.msg;

            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                state.msg = '';
                state.type = '';
            }, 5000)
        },
        /**
         * @param {object} state
         * @return void
         */
        clearNotify(state) {
            state.type = '';
            state.msg = '';
        }
    },
    getters: {
        msg: state => state.msg,
        msgType: state => state.type,
    }
};
