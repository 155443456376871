var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-wrap" },
    [
      _c("page-header", { attrs: { title: "Settings" } }),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("settings-sync", {
              staticClass: "col-6 py-4",
              attrs: { service: "gofilex", timestamp: _vm.gofilexLastSync },
            }),
            _vm._v(" "),
            _c("settings-sync", {
              staticClass: "col-6 py-4",
              attrs: { service: "kinepolis", timestamp: _vm.kinepolisLastSync },
            }),
            _vm._v(" "),
            _c("settings-exclude-theaters", {
              staticClass: "col-12 mb-4",
              attrs: { arr: _vm.importExcluded },
              on: { change: (tags) => (_vm.importExcluded = tags) },
            }),
            _vm._v(" "),
            _vm._m(1),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 text-right" }, [
            _c("div", { staticClass: "py-4" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Save")]
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "border-bottom" }, [_vm._v("Theatres sync")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "border-bottom" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }