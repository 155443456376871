<template>
    <div class="theater-view">
        <div v-if="show">Theater closing information</div>
    </div>
</template>

<script>
    export default {
        props: ['show'],
        data () {
            return {
            }
        },
    }
</script>
