import Login from './components/Login';
import Theaters from './components/Theaters';
import NotFound from './components/NotFound';
import Settings from './components/Settings';

export default [
    {
        path: '/login',
        component: Login,
        meta: {
            stargateAuth: false,
        }
    },
    {
        path: '/',
        alias: '/theaters',
        component: Theaters,
        meta: {
            stargateAuth: true,
            abilities: ['*'],
        }
    },
    {
        path: '/settings',
        component: Settings,
        meta: {
            stargateAuth: true,
            abilities: ['*'],
        }
    },
    {
        // 404
        path: '*',
        component: NotFound,
    }
];
