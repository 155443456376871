<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container">

                <div class="modal-header">
                    <slot name="header">
                        Edit screen
                    </slot>
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="col-xs-6">
                            <b-form-group label-cols-sm="3" label="Width:" label-align-sm="right" label-for="scr-width">
                                <b-form-input id="scr-width" type="number"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-xs-6">
                            <b-form-group label-cols-sm="3" label="Height:" label-align-sm="right" label-for="scr-height">
                                <b-form-input id="scr-height" type="number"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-6">
                            <b-form-group label-cols-sm="3" label="Seats:" label-align-sm="right" label-for="scr-seats">
                                <b-form-input v-model="screen.seats" id="scr-seats" type="number"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-xs-6">
                            <b-form-group label-cols-sm="3" label="Group:" label-align-sm="right" label-for="scr-group">
                                <b-form-select id="scr-group" :options="groups"></b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button class="modal-default-button" @click="$emit('close')">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {BFormGroup, BFormInput, BFormSelect} from 'bootstrap-vue/esm/index.js';

    export default {
        components: {
            BFormGroup,
            BFormInput,
            BFormSelect
        },
        props: ['screen', 'hello'],
        data() {
            return {
                showing: false,
                types: [
                    'number',
                    'date',
                ],
                groups: [
                    { value: null, text: 'Please select an option' },
                    { value: 'A', text: 'A' },
                    { value: 'B', text: 'B' }
                ]
            }
        },
        mounted() {
            this.showing= true;
            console.log('screen modal mounted', this.hello, this);
        },
        watch: {
            showing: {
                handler(newN) {
                    console.log('showing change', newN);
                }
            }
        },
    }
</script>
