<template>
    <nav class="oc-menu oc-menu-left bg-darkest">
        <div class="oc-menu-title">Filters</div>

        <div class="filter-group">
            <b-button block v-b-toggle.regions class="d-flex justify-content-between">
                Regions
                <span>
                    <span class="badge badge-secondary">{{ regions.length }}</span>
                    <chevron-down-icon />
                </span>
            </b-button>
            <b-collapse id="regions" accordion="regions">
                <b-list-group>
                    <b-list-group-item v-for="region in regions" v-bind:key="region.name">
                        <label class="switch">
                            <input type="checkbox" :value="region.name" v-on:change="(e) => filter(e, 'region')"/>
                            <span>{{ region.name }}</span>
                        </label>
                    </b-list-group-item>
                </b-list-group>
            </b-collapse>
        </div>

        <div class="filter-group">
            <b-button block v-b-toggle.cineparks class="d-flex justify-content-between">
                CineParks
                <span>
                    <span class="badge badge-secondary">{{ cineParks.length }}</span>
                    <chevron-down-icon />
                </span>
            </b-button>
            <b-collapse id="cineparks" accordion="cineparks">
                <b-list-group>
                    <b-list-group-item v-for="park in cineParks" v-bind:key="park.name">
                        <label class="switch">
                            <input type="checkbox" :value="park.name" v-on:change="(e) => filter(e, 'park')"/>
                            <span>{{ park.name }}</span>
                        </label>
                    </b-list-group-item>
                </b-list-group>
            </b-collapse>
        </div>

        <div class="filter-group">
            <b-button block v-b-toggle.video-tags class="d-flex justify-content-between">
                Video tags
                <span>
                    <span class="badge badge-secondary">{{ videoTags.length }}</span>
                    <chevron-down-icon />
                </span>
            </b-button>
            <b-collapse id="video-tags" accordion="video-tags">
                <b-list-group>
                    <b-list-group-item v-for="vTag in videoTags" v-bind:key="vTag.name">
                        <label class="switch">
                            <input type="checkbox" :value="vTag.name" v-on:change="(e) => filter(e, 'video')"/>
                            <span>{{ vTag.name }}</span>
                        </label>
                    </b-list-group-item>
                </b-list-group>
            </b-collapse>
        </div>

        <div class="filter-group">
            <b-button block v-b-toggle.audio-tags class="d-flex justify-content-between">
                Audio tags
                <span>
                    <span class="badge badge-secondary">{{ audioTags.length }}</span>
                    <chevron-down-icon />
                </span>
            </b-button>
            <b-collapse id="audio-tags" accordion="audio-tags">
                <b-list-group>
                    <b-list-group-item v-for="aTag in audioTags" v-bind:key="aTag.name">
                        <label class="switch">
                            <input type="checkbox" :value="aTag.name" v-on:change="(e) => filter(e, 'audio')"/>
                            <span>{{ aTag.name }}</span>
                        </label>
                    </b-list-group-item>
                </b-list-group>
            </b-collapse>
        </div>
    </nav>
</template>

<script>
    import {BListGroup, BListGroupItem, BButton} from 'bootstrap-vue/esm/index.js';
    import {ChevronDownIcon} from 'vue-feather-icons';

    export default {
        components: {
            BListGroup,
            BListGroupItem,
            BButton,
            ChevronDownIcon
        },
        data() {
            return {
                regions: [],
                cineParks: [],
                videoTags: [],
                audioTags: [],
                selection: [],
                checked: 0
            }
        },
        mounted() {
            this.$store.watch((state, getters) => {
                const tags = getters['filters/tags'];
                this.regions = tags.filter(tag => tag.type === 'region') || [];
                this.cineParks = tags.filter(tag => tag.type === 'park') || [];
                this.videoTags = tags.filter(tag => tag.type === 'video') || [];
                this.audioTags = tags.filter(tag => tag.type === 'audio') || [];
            })
        },
        methods: {
            filter(e, type) {
                const el = e.currentTarget, value = type + '-' + el.value;

                if (el.checked) {
                    this.selection.push(value);
                } else {
                    this.selection.splice(this.selection.indexOf(value, 1));
                }

                const query = this.selection.length ? '?filter=' + encodeURIComponent(this.selection.join(',')) : '';

                this.$router.replace('/theaters' + query);

                this.$store.commit('filters/filter', this.selection)
            }
        }
    }
</script>
