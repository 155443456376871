<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>{{ title }}</h1>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PageHeader",
        props: ['title']
    }
</script>
