var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "view-404 fixed-center" }, [
    _c("h4", [_vm._v("This page doesn't exist.")]),
    _vm._v(" "),
    _c("p", [
      _c("a", { attrs: { href: "#" }, on: { click: _vm.back } }, [
        _c("i", { staticClass: "ico-arrow-left-circle" }),
        _vm._v(" Go back"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }