<template>
    <div class="theater-view">
        <b-form v-if="show" class="needs-validation" novalidate @submit.stop.prevent="submit">

            <div class="form-row">
                <b-form-group class="col-md-9" label="Name" label-for="th-name">
                    <b-form-input type="text"
                                  class="form-control"
                                  id="th-name"
                                  placeholder="Theater name"
                                  v-model="name"
                                  required/>
                    <b-form-invalid-feedback class="invalid-tooltip" v-if="!$v.name.required">
                        A name is required
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group class="col-md-3" label="Code" label-for="th-code">
                    <b-form-input type="text"
                                  class="form-control"
                                  id="th-code"
                                  placeholder="Code"
                                  v-model="code"
                                  required/>
                    <b-form-invalid-feedback class="invalid-tooltip" v-if="!$v.code.required">
                        A code is required
                    </b-form-invalid-feedback>
                </b-form-group>
            </div>

            <div class="form-row">
                <div class="form-group col-md-8">
                    <label for="th-tags-name">Alternative names</label>
                    <vue-tags-input
                        id="th-tags-name"
                        placeholder="Alternative theatre names"
                        v-model="currentTags.name"
                        :tags="tags.name"
                        @tags-changed="newTags => tags.name = newTags"/>
                </div>
                <div class="form-group col-md-4">
                    <label for="aa-playlist-api-key">Advertising Accord Playlist API key</label>
                    <input type="text" class="form-control" id="aa-playlist-api-key"
                           placeholder="API key"
                           v-model="aa_playlist_api_key">
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="th-tags-park">CineParks</label>
                    <vue-tags-input
                            id="th-tags-park"
                            placeholder="Brightfish parks for this theatre"
                            v-model="currentTags.park"
                            :tags="tags.park"
                            :autocomplete-items="parks"
                            add-only-from-autocomplete
                            @tags-changed="newTags => tags.park = newTags"/>
                </div>

                <div :class="'mb-3 col-md-' + (theater.kinepolis_id ? '3' : '6')">
                    <label for="gofilex-id">Gofilex ID</label>
                    <input type="text" class="form-control" id="gofilex-id" required readonly :value="theater.gofilex_id">
                </div>

                <div v-if="theater.kinepolis_id" class="col-md-3 mb-3">
                    <label for="kinepolis-id">Kinepolis Code</label>
                    <input type="text" class="form-control" id="kinepolis-id" required readonly :value="theater.kinepolis_id">
                </div>
            </div>

            <div class="form-row mt-3 align-items-center pl-1">
                <div class="mr-4">
                    <button class="btn btn-primary" type="submit">
                        <span class="d-none spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        {{ create ? 'Create' : 'Update' }}
                    </button>
                </div>
                <div class="mr-4">
                    <b-form-checkbox v-model="adm_include" name="adm_include" switch size="md">
                        Include in admission stats
                    </b-form-checkbox>
                </div>
                <div>
                    <b-form-checkbox v-model="active" name="active" switch size="md">
                        Active theatre
                    </b-form-checkbox>
                </div>
            </div>
        </b-form>
    </div>
</template>

<script>
    import {BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormCheckbox} from 'bootstrap-vue/esm/index.js';
    import VueTagsInput from '@johmun/vue-tags-input';
    import {required, numeric} from 'vuelidate/lib/validators';

    export default {
        components: {
            BForm,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            VueTagsInput,
            BFormCheckbox,
        },
        props: ['show', 'theater', 'create'],
        data() {
            return {
                form: 'settings',
                name: this.theater.name,
                code: this.theater.code,
                active: this.theater.active,
                adm_include: this.theater.adm_include,
                aa_playlist_api_key: this.theater.aa_playlist_api_key,
                tags: {
                    park: this.parseTagsFromServer('park', this.theater.tags),
                    name: this.parseTagsFromServer('name', this.theater.tags),
                },
                currentTags: {
                    park: '',
                    name: '',
                },
                parks: this.$store.getters['filters/park'].map(p => ({text: p.name})),
            }
        },
        validations: {
            name: {required},
            code: {required},

        },
        watch: {
            active(value) {
                this.theater.active = value;
            },
            adm_include(value) {
                this.theater.adm_include = value;
            },
        },
        methods: {
            submit() {
                this.$el.querySelector('form').classList.add('was-validated');

                this.$v.$touch();

                if (this.$v.$anyError) {
                    return;
                }

                this.spinBtn(this.$el);

                if (this.create) {
                    this.store();
                } else {
                    this.update();
                }
            },
            store() {
                this.$http
                    .post('theaters', this.formatData(this.$data))
                    .then(() => {
                        this.spinBtn(this.$el, 0);
                        this.toast('success', this.theater.name, 'Theater address data saved!');
                        this.$bvModal.hide('create-theatre');
                    })
                    .catch(error => {
                        this.spinBtn(this.$el, 0);
                        this.toast('danger', 'Error saving theater', error.message);
                    });
            },
            update() {
                this.$http
                    .put('theaters/' + this.theater.id, this.formatData(this.$data))
                    .then(() => {
                        this.spinBtn(this.$el, 0);
                        this.toast('success', this.theater.name, 'Theater address data saved!');
                    })
                    .catch(error => {
                        this.spinBtn(this.$el, 0);
                        this.toast('danger', 'Error saving theater', error.message);
                    });
            },
            formatData(data) {
                data = Object.assign({}, data);

                delete data.currentTags;

                data.tags = {
                    park: data.tags.park.map(tag => tag.text),
                    name: data.tags.name.map(tag => tag.text),
                };

                return data;
            },
            /**
             * Only keep tag names
             * @return {array}
             */
            parseTagsFromServer(type, tags = []) {
                return tags
                    .filter(tag => tag.type === type)
                    .map(tag => ({text: tag.name.en}))
            }
        }
    }
</script>
