/** Vuex module */
export default {
    namespaced: true,
    state: {
        items: [],
        filters: [],
    },
    mutations: {
        /**
         * @param {object} state
         * @param {array} data
         * @return void
         */
        add(state, data) {
            let cache = [];

            data = data.map(tag => ({
                type: tag.type,
                name: tag.name.en
            }));

            state.items = state.items
                .concat(data)
                .filter(item => {
                    if (cache.indexOf(item.type + item.name) === -1) {
                        cache.push(item.type + item.name);
                        return true;
                    }
                    return false;
                });
        },
        filter(state, data) {
            state.filters = data
        }
    },
    getters: {
        tags: state => (state.items || []),
        filter: state => (state.filters || []),
        park: state => (state.items ? state.items.filter(tag => tag.type === 'park') : []),
    }
};
