<template>
    <login app-name="Park"
           app-logo="https://images.brightfish.be/apps/park/park-logo-colored-428.png"
           app-logo-width="180"
           title="Exhibitors and screens inventory"
           bg-color="#2b4d42"></login>
</template>

<script>
import Login from "@brightfish/stargate-client-pkce/src/components/Login";

export default {
    components: {
        Login
    }
}
</script>
