<template>
    <div class="app-wrap">
        <page-header title="Settings"></page-header>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="border-bottom">Theatres sync</div>
                </div>
                <settings-sync service="gofilex" class="col-6 py-4" :timestamp="gofilexLastSync"></settings-sync>
                <settings-sync service="kinepolis" class="col-6 py-4" :timestamp="kinepolisLastSync"></settings-sync>
                <settings-exclude-theaters class="col-12 mb-4" :arr="importExcluded" @change="tags => importExcluded = tags"/>
                <div class="col-12"><div class="border-bottom"></div></div>
            </div>
            <div class="row">
                <div class="col-12 text-right">
                    <div class="py-4">
                        <button class="btn btn-primary" type="submit" @click.prevent="save">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from "../partials/PageHeader";
    import SettingsSync from "../partials/SettingsSync";
    import SettingsExcludeTheaters from "../partials/SettingsExcludeTheaters";
    import VueTagsInput from "@johmun/vue-tags-input";

    export default {
        components: {
            SettingsExcludeTheaters,
            SettingsSync,
            PageHeader
        },
        data() {
            return {
                importExcluded: [],
                gofilexLastSync: '',
                kinepolisLastSync: '',
            }
        },
        created() {
            this.fetch();
        },
        methods: {
            /**
             * Get all settings from Laravel.
             * @return void
             */
            fetch() {
                this.$http
                    .get('settings')
                    .then(response => {
                        this.importExcluded = (response.data.data.import_excluded || []).map(tag => ({text: tag}));
                        this.gofilexLastSync = response.data.data.health_age_gofilex;
                        this.kinepolisLastSync = response.data.data.health_age_kinepolis;
                    })
                    .catch(error => this.toast('danger', `Error fetching settings!`, error.message));
            },
            /**
             * Update all settings in database.
             * @return void
             */
            save() {
                this.$http
                    .patch('settings', {
                        import_excluded: this.importExcluded.map(tag => tag.text)
                    })
                    .then(() => this.toast('success', 'Settings saved!', 'All settings have successfully been saved.'))
                    .catch(error => this.toast('danger', `Error saving setting!`, error.message));
            },
        }
    }
</script>
