var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "theater-view" }, [
    _vm.show ? _c("div", [_vm._v("Theater closing information")]) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }