var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "theater-view" },
    [
      _vm.show
        ? _c(
            "b-form",
            {
              staticClass: "needs-validation",
              attrs: { novalidate: "" },
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-md-9",
                      attrs: { label: "Contact name", "label-for": "c-name" },
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "c-name",
                          placeholder: "Contact person",
                          required: "",
                        },
                        model: {
                          value: _vm.contact_name,
                          callback: function ($$v) {
                            _vm.contact_name = $$v
                          },
                          expression: "contact_name",
                        },
                      }),
                      _vm._v(" "),
                      !_vm.$v.contact_name.required
                        ? _c(
                            "b-form-invalid-feedback",
                            { staticClass: "invalid-tooltip" },
                            [
                              _vm._v(
                                "\n                    A contact person is required\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-md-3",
                      attrs: {
                        label: "Preferred language",
                        "label-for": "c-lang",
                      },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "c-lang",
                          options: _vm.languages,
                          state: _vm.$v.preferred_lang.$dirty
                            ? !_vm.$v.preferred_lang.$error
                            : null,
                        },
                        model: {
                          value: _vm.preferred_lang,
                          callback: function ($$v) {
                            _vm.preferred_lang = $$v
                          },
                          expression: "preferred_lang",
                        },
                      }),
                      _vm._v(" "),
                      !_vm.$v.preferred_lang.required
                        ? _c(
                            "b-form-invalid-feedback",
                            { staticClass: "invalid-tooltip" },
                            [
                              _vm._v(
                                "\n                    A language is required\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-md-6 mb-3",
                      attrs: {
                        label: "Mobile number",
                        "label-for": "c-mobile",
                      },
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "tel",
                          pattern: "\\+[0-9]{11}",
                          id: "c-mobile",
                          placeholder: "+32444555666",
                          required: "",
                        },
                        model: {
                          value: _vm.mobile,
                          callback: function ($$v) {
                            _vm.mobile = $$v
                          },
                          expression: "mobile",
                        },
                      }),
                      _vm._v(" "),
                      !_vm.$v.mobile.required
                        ? _c(
                            "b-form-invalid-feedback",
                            { staticClass: "invalid-tooltip" },
                            [
                              _vm._v(
                                "\n                    A mobile number is required\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-md-6 mb-3",
                      attrs: { label: "Phone number", "label-for": "c-phone" },
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "tel",
                          pattern: "\\+[0-9]{10}",
                          id: "c-phone",
                          placeholder: "+3245556677",
                        },
                        model: {
                          value: _vm.phone,
                          callback: function ($$v) {
                            _vm.phone = $$v
                          },
                          expression: "phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-md-6 mb-3",
                      attrs: { label: "Contact email", "label-for": "c-mail" },
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "email",
                          id: "c-mail",
                          placeholder: "Email address",
                          required: "",
                        },
                        model: {
                          value: _vm.email,
                          callback: function ($$v) {
                            _vm.email = $$v
                          },
                          expression: "email",
                        },
                      }),
                      _vm._v(" "),
                      !_vm.$v.email.required
                        ? _c(
                            "b-form-invalid-feedback",
                            { staticClass: "invalid-tooltip" },
                            [
                              _vm._v(
                                "\n                    An email address is required\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-md-6 mb-3",
                      attrs: { label: "Theater website", "label-for": "c-web" },
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "url",
                          id: "c-web",
                          placeholder: "URL",
                          required: "",
                        },
                        model: {
                          value: _vm.website,
                          callback: function ($$v) {
                            _vm.website = $$v
                          },
                          expression: "website",
                        },
                      }),
                      _vm._v(" "),
                      !_vm.$v.website.required
                        ? _c(
                            "b-form-invalid-feedback",
                            { staticClass: "invalid-tooltip" },
                            [
                              _vm._v(
                                "\n                    The website is required\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-md-12 mb-3",
                      attrs: {
                        label: "Street address",
                        "label-for": "th-address",
                      },
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "th-address",
                          placeholder: "Street address",
                          required: "",
                        },
                        model: {
                          value: _vm.address_line_1,
                          callback: function ($$v) {
                            _vm.address_line_1 = $$v
                          },
                          expression: "address_line_1",
                        },
                      }),
                      _vm._v(" "),
                      !_vm.$v.zip.required
                        ? _c(
                            "b-form-invalid-feedback",
                            { staticClass: "invalid-tooltip" },
                            [
                              _vm._v(
                                "\n                    This is required\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-md-3 mb-3",
                      attrs: { label: "Zip", "label-for": "th-zip" },
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          id: "th-zip",
                          placeholder: "Zip",
                          required: "",
                          min: "0",
                        },
                        model: {
                          value: _vm.zip,
                          callback: function ($$v) {
                            _vm.zip = $$v
                          },
                          expression: "zip",
                        },
                      }),
                      _vm._v(" "),
                      !_vm.$v.zip.numeric
                        ? _c(
                            "b-form-invalid-feedback",
                            { staticClass: "invalid-tooltip" },
                            [
                              _vm._v(
                                "\n                    The postal code should be a number\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$v.zip.required
                        ? _c(
                            "b-form-invalid-feedback",
                            { staticClass: "invalid-tooltip" },
                            [
                              _vm._v(
                                "\n                    This is required\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-3 mb-3" }, [
                    _c("label", { attrs: { for: "validationTooltip03" } }, [
                      _vm._v("City"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.city,
                          expression: "city",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "validationTooltip03",
                        placeholder: "City",
                        required: "",
                      },
                      domProps: { value: _vm.city },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.city = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "invalid-tooltip" }, [
                      _vm._v(
                        "\n                    Please provide a valid city\n                "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group col-md-3" },
                    [
                      _c(
                        "label",
                        { attrs: { for: "th-tags-region-" + _vm.code } },
                        [_vm._v("Region")]
                      ),
                      _vm._v(" "),
                      _c("vue-tags-input", {
                        attrs: {
                          id: "th-tags-region-" + _vm.code,
                          placeholder: "Region",
                          tags: _vm.tags.region,
                          "max-tags": 1,
                          autocompleteItems: _vm.$config.regions.map((r) => ({
                            text: r,
                          })),
                          validation: _vm.regionValidation(),
                        },
                        on: {
                          "tags-changed": (newTags) =>
                            (_vm.tags.region = newTags),
                        },
                        model: {
                          value: _vm.currentTags.region,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentTags, "region", $$v)
                          },
                          expression: "currentTags.region",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "invalid-tooltip" }, [
                        _vm._v(
                          "\n                    Please provide a valid region\n                "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-3 mb-3" }, [
                    _c("label", { attrs: { for: "validationTooltip04" } }, [
                      _vm._v("Country"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.country,
                          expression: "country",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "validationTooltip04",
                        placeholder: "State",
                        required: "",
                      },
                      domProps: { value: _vm.country },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.country = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "invalid-tooltip" }, [
                      _vm._v(
                        "\n                    Please provide a valid country\n                "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                [
                  _c("span", {
                    staticClass: "d-none spinner-border spinner-border-sm",
                    attrs: { role: "status", "aria-hidden": "true" },
                  }),
                  _vm._v("\n            Update\n        "),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }