var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass:
            "d-flex w-100 align-items-center justify-content-between",
        },
        [
          _c("div", { staticClass: "col-auto p-0" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: { click: _vm.shiftAppLeft },
              },
              [_c("equaliser-icon")],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-auto px-2 flex-lg-grow-1" }, [
            _c("div", { staticClass: "input-group my-4" }, [
              _c("input", {
                staticClass: "form-control",
                attrs: {
                  type: "search",
                  placeholder: "Search theaters",
                  "aria-label": "Search a theater",
                  "aria-describedby": "btn-search",
                },
                domProps: { value: _vm.value },
                on: { input: (e) => this.$emit("input", e.target.value) },
              }),
              _vm._v(" "),
              _vm._m(0),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-auto p-0 pr-2" }, [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "b-modal",
                    rawName: "v-b-modal.create-theatre",
                    modifiers: { "create-theatre": true },
                  },
                ],
                staticClass: "btn btn-primary",
              },
              [_c("plus-icon")],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-auto p-0" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: { click: _vm.shiftAppTop },
              },
              [_c("menu-icon")],
              1
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-append" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "button", id: "btn-search" },
        },
        [_vm._v("Search")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }