var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { staticClass: "oc-menu oc-menu-left bg-darkest" }, [
    _c("div", { staticClass: "oc-menu-title" }, [_vm._v("Filters")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "filter-group" },
      [
        _c(
          "b-button",
          {
            directives: [
              {
                name: "b-toggle",
                rawName: "v-b-toggle.regions",
                modifiers: { regions: true },
              },
            ],
            staticClass: "d-flex justify-content-between",
            attrs: { block: "" },
          },
          [
            _vm._v("\n            Regions\n            "),
            _c(
              "span",
              [
                _c("span", { staticClass: "badge badge-secondary" }, [
                  _vm._v(_vm._s(_vm.regions.length)),
                ]),
                _vm._v(" "),
                _c("chevron-down-icon"),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "b-collapse",
          { attrs: { id: "regions", accordion: "regions" } },
          [
            _c(
              "b-list-group",
              _vm._l(_vm.regions, function (region) {
                return _c("b-list-group-item", { key: region.name }, [
                  _c("label", { staticClass: "switch" }, [
                    _c("input", {
                      attrs: { type: "checkbox" },
                      domProps: { value: region.name },
                      on: { change: (e) => _vm.filter(e, "region") },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(region.name))]),
                  ]),
                ])
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "filter-group" },
      [
        _c(
          "b-button",
          {
            directives: [
              {
                name: "b-toggle",
                rawName: "v-b-toggle.cineparks",
                modifiers: { cineparks: true },
              },
            ],
            staticClass: "d-flex justify-content-between",
            attrs: { block: "" },
          },
          [
            _vm._v("\n            CineParks\n            "),
            _c(
              "span",
              [
                _c("span", { staticClass: "badge badge-secondary" }, [
                  _vm._v(_vm._s(_vm.cineParks.length)),
                ]),
                _vm._v(" "),
                _c("chevron-down-icon"),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "b-collapse",
          { attrs: { id: "cineparks", accordion: "cineparks" } },
          [
            _c(
              "b-list-group",
              _vm._l(_vm.cineParks, function (park) {
                return _c("b-list-group-item", { key: park.name }, [
                  _c("label", { staticClass: "switch" }, [
                    _c("input", {
                      attrs: { type: "checkbox" },
                      domProps: { value: park.name },
                      on: { change: (e) => _vm.filter(e, "park") },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(park.name))]),
                  ]),
                ])
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "filter-group" },
      [
        _c(
          "b-button",
          {
            directives: [
              {
                name: "b-toggle",
                rawName: "v-b-toggle.video-tags",
                modifiers: { "video-tags": true },
              },
            ],
            staticClass: "d-flex justify-content-between",
            attrs: { block: "" },
          },
          [
            _vm._v("\n            Video tags\n            "),
            _c(
              "span",
              [
                _c("span", { staticClass: "badge badge-secondary" }, [
                  _vm._v(_vm._s(_vm.videoTags.length)),
                ]),
                _vm._v(" "),
                _c("chevron-down-icon"),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "b-collapse",
          { attrs: { id: "video-tags", accordion: "video-tags" } },
          [
            _c(
              "b-list-group",
              _vm._l(_vm.videoTags, function (vTag) {
                return _c("b-list-group-item", { key: vTag.name }, [
                  _c("label", { staticClass: "switch" }, [
                    _c("input", {
                      attrs: { type: "checkbox" },
                      domProps: { value: vTag.name },
                      on: { change: (e) => _vm.filter(e, "video") },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(vTag.name))]),
                  ]),
                ])
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "filter-group" },
      [
        _c(
          "b-button",
          {
            directives: [
              {
                name: "b-toggle",
                rawName: "v-b-toggle.audio-tags",
                modifiers: { "audio-tags": true },
              },
            ],
            staticClass: "d-flex justify-content-between",
            attrs: { block: "" },
          },
          [
            _vm._v("\n            Audio tags\n            "),
            _c(
              "span",
              [
                _c("span", { staticClass: "badge badge-secondary" }, [
                  _vm._v(_vm._s(_vm.audioTags.length)),
                ]),
                _vm._v(" "),
                _c("chevron-down-icon"),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "b-collapse",
          { attrs: { id: "audio-tags", accordion: "audio-tags" } },
          [
            _c(
              "b-list-group",
              _vm._l(_vm.audioTags, function (aTag) {
                return _c("b-list-group-item", { key: aTag.name }, [
                  _c("label", { staticClass: "switch" }, [
                    _c("input", {
                      attrs: { type: "checkbox" },
                      domProps: { value: aTag.name },
                      on: { change: (e) => _vm.filter(e, "audio") },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(aTag.name))]),
                  ]),
                ])
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }