<template>
    <li :class="topClassnames">
        <div class="theater-row bg-dark p-3 pr-4" v-on:click="toggle">
            <div class="theater-title">
                <code v-if="!create" class="badge badge-warning m-2 mr-3">{{ theater.code }}</code>
                <h4 class="m-0">{{ !create ? theater.name : 'Add a new theatre' }}</h4>
            </div>
            <div v-if="!create" class="theater-actions">
                <activity-icon v-if="theater.active"/>
                <alert-triangle-icon v-else color="orange"/>
            </div>
        </div>
        <div class="theater-views-wrap bg-base" :style="{height: height + 'px'}">
            <b-nav v-if="!create" tabs class="nav theater-nav-tabs">
                <b-nav-item class="bg-dark" @click="() => switchView(0)" :active="shift === 0">Settings</b-nav-item>
                <b-nav-item class="bg-dark" @click="() => switchView(1)" :active="shift === 1">Contact</b-nav-item>
                <b-nav-item class="bg-dark" @click="() => switchView(2)" :active="shift === 2">Screens</b-nav-item>
                <b-nav-item class="bg-dark" @click="() => switchView(3)" :active="shift === 3">Closing times</b-nav-item>
            </b-nav>
            <div class="theater-views" :style="{transform:'translate3d(-' + (shift * 25) + '%,0,0)'}">
                <theater-settings :theater="theater" :code="theater.code" :show="shift === 0" :create="create"/>
                <theater-contact :theater="theater" :code="theater.code" :show="shift === 1"/>
                <theater-screens :screens="theater.screens" :show="shift === 2"/>
                <theater-closings :theater="theater.closingTimes" :show="shift === 3"/>
            </div>
        </div>
    </li>
</template>

<script>
    import {BNav, BNavItem} from 'bootstrap-vue/esm/index.js';
    import {EyeIcon, Edit2Icon, ActivityIcon, AlertTriangleIcon} from 'vue-feather-icons'
    import TheaterSettings from './TheaterSettings';
    import TheaterContact from './TheaterContact';
    import TheaterScreens from './TheaterScreens';
    import TheaterClosings from './TheaterClosings';

    export default {
        components: {
            BNav,
            BNavItem,
            EyeIcon,
            Edit2Icon,
            ActivityIcon,
            AlertTriangleIcon,
            TheaterContact,
            TheaterSettings,
            TheaterScreens,
            TheaterClosings
        },
        props: {
            theater: {
                type: Object,
                required: true
            },
            create: {
                type: Boolean,
                default: false,
            }
        },
        data () {
            return {
                show: false,
                shift: -1,
                height: 0
            }
        },
        mounted() {
            if (this.create) {
                this.toggle();
            }
        },
        computed: {
            topClassnames: function () {
                return {
                    'open': this.show,
                    'theater w-100': this.create,
                    'theater mb-2': !this.create,
                }
            }
        },
        methods: {
            toggle() {
                // Only allow to open the row in the 'create' modal.
                if (this.create && this.show) return;

                this.show = !this.show;
                this.shift = this.show ? 0 : -1;
                this.setHeight();
            },
            switchView(eq) {
                this.shift = eq;
                this.setHeight();
            },
            setHeight() {
                // Using rAF to allow Vue the time to render the subview on this.show
                requestAnimationFrame(() => {
                    if (!this.show) {
                        this.height = 0;
                        return;
                    }
                    const view = this.$el.querySelector(`.theater-view:nth-child(${this.shift + 1})`).firstElementChild;
                    this.height = view.clientHeight + 40;
                });
            },
        }
    }
</script>
