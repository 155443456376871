var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", { class: _vm.topClassnames }, [
    _c(
      "div",
      {
        staticClass: "theater-row bg-dark p-3 pr-4",
        on: { click: _vm.toggle },
      },
      [
        _c("div", { staticClass: "theater-title" }, [
          !_vm.create
            ? _c("code", { staticClass: "badge badge-warning m-2 mr-3" }, [
                _vm._v(_vm._s(_vm.theater.code)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("h4", { staticClass: "m-0" }, [
            _vm._v(
              _vm._s(!_vm.create ? _vm.theater.name : "Add a new theatre")
            ),
          ]),
        ]),
        _vm._v(" "),
        !_vm.create
          ? _c(
              "div",
              { staticClass: "theater-actions" },
              [
                _vm.theater.active
                  ? _c("activity-icon")
                  : _c("alert-triangle-icon", { attrs: { color: "orange" } }),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "theater-views-wrap bg-base",
        style: { height: _vm.height + "px" },
      },
      [
        !_vm.create
          ? _c(
              "b-nav",
              { staticClass: "nav theater-nav-tabs", attrs: { tabs: "" } },
              [
                _c(
                  "b-nav-item",
                  {
                    staticClass: "bg-dark",
                    attrs: { active: _vm.shift === 0 },
                    on: { click: () => _vm.switchView(0) },
                  },
                  [_vm._v("Settings")]
                ),
                _vm._v(" "),
                _c(
                  "b-nav-item",
                  {
                    staticClass: "bg-dark",
                    attrs: { active: _vm.shift === 1 },
                    on: { click: () => _vm.switchView(1) },
                  },
                  [_vm._v("Contact")]
                ),
                _vm._v(" "),
                _c(
                  "b-nav-item",
                  {
                    staticClass: "bg-dark",
                    attrs: { active: _vm.shift === 2 },
                    on: { click: () => _vm.switchView(2) },
                  },
                  [_vm._v("Screens")]
                ),
                _vm._v(" "),
                _c(
                  "b-nav-item",
                  {
                    staticClass: "bg-dark",
                    attrs: { active: _vm.shift === 3 },
                    on: { click: () => _vm.switchView(3) },
                  },
                  [_vm._v("Closing times")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "theater-views",
            style: { transform: "translate3d(-" + _vm.shift * 25 + "%,0,0)" },
          },
          [
            _c("theater-settings", {
              attrs: {
                theater: _vm.theater,
                code: _vm.theater.code,
                show: _vm.shift === 0,
                create: _vm.create,
              },
            }),
            _vm._v(" "),
            _c("theater-contact", {
              attrs: {
                theater: _vm.theater,
                code: _vm.theater.code,
                show: _vm.shift === 1,
              },
            }),
            _vm._v(" "),
            _c("theater-screens", {
              attrs: { screens: _vm.theater.screens, show: _vm.shift === 2 },
            }),
            _vm._v(" "),
            _c("theater-closings", {
              attrs: {
                theater: _vm.theater.closingTimes,
                show: _vm.shift === 3,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }